.datagrid-custom {
    background-color: #f5f5f5;
    /* Gris claro para el fondo del DataGrid */
}

.header-vendedor {
    background-color: #d1d1d1;
    /* Gris más oscuro para el encabezado de la columna de vendedores */
}

.cell-vendedor {
    background-color: #e6e6e6;
    /* Gris intermedio para las celdas de la columna de vendedores */
    border-bottom: 1px solid #b0b0b0;
    /* Línea horizontal para las celdas */
}

.header-columna {
    background-color: #e6e6e6;
    /* Gris intermedio para los encabezados de las otras columnas */
    border-bottom: 1px solid #b0b0b0;
    border-right: 1px solid #b0b0b0;
    /* Línea horizontal entre encabezados */
}

.cell-columna {
    border-bottom: 1px solid #b0b0b0;
    border-right: 1px solid #b0b0b0;
    /* Línea horizontal entre celdas */
}

.sticky-column {
    position: sticky;
    left: 0;
    z-index: 2;
    background-color: white;
}